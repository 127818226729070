<uc-user-access-box [tenantSettings]="undefined">
    <ng-container content>
        <ng-template [ngIf]="challenge" [ngIfElse]="select" [ngSwitch]="challenge">
            <ng-template [ngIf]="mfaStatus">

                <ng-template [ngSwitchCase]="mfaChallengeType.Virtual">
                    <uf-virtual-mfa *ngIf="label || mfaStatus === mfaStatuses.MfaVerifyRequired" [label]="label"
                        [mfaStatus]="mfaStatus" [setVirtualMfaCode]="setVirtualMfaCode.bind(this)"
                        [verifyToken]="verifyVirtualMfaToken.bind(this)" />
                </ng-template>

                <uf-recovery-codes *ngSwitchCase="mfaChallengeType.RecoveryCode" [mfaStatus]="mfaStatus"
                    [setRecoveryCodes]="setRecoveryCodes.bind(this)" [verifyCode]="verifyRecoveryCode.bind(this)" />

                <uf-sms *ngSwitchCase="mfaChallengeType.Sms" [mfaStatus]="mfaStatus"
                    [smsChallenges]="smsChallenges.bind(this)" [verifyCode]="verifySmsCode.bind(this)" />

                <uf-web-authn *ngSwitchCase="mfaChallengeType.Device" [mfaStatus]="mfaStatus"
                    [setupCredential]="setupCredential.bind(this)" [getSetupChallenge]="getSetupChallenge.bind(this)"
                    [verifyCredential]="verifyCredential.bind(this)"
                    [getVerifyChallenge]="getVerifyChallenge.bind(this)" />

            </ng-template>
        </ng-template>

        <ng-template #select>
            <ng-template [ngIf]="mfaStatus">
                <uf-mfa-select [mfaStatus]="mfaStatus" [mfaAcceptedChallenge]="acceptedChallenges"
                    (selectProvider)="selectProvider($event)" />
            </ng-template>
        </ng-template>

        <div class="col center-all">

            <button *ngIf="challenge && acceptedChallenges.length > 1" (click)="challenge = undefined" type="button"
                class="uf-button tertiary">
                {{commonTK.MfaChooseMethod | translate}}
            </button>

            <button (click)="logout()" type="button" class="uf-button tertiary">
                {{sharedTermsTK.ActionLogout | translate}}
            </button>
        </div>
        <uf-message *ngIf="error" [content]="error" icon="errorSolid" class="error" />
    </ng-container>
</uc-user-access-box>