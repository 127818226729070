"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MeClient = void 0;
const constants_1 = require("../constants");
const functions_1 = require("../functions");
/** Client for v0/me APIs */
class MeClient {
    constructor(client) {
        this.client = client;
    }
    // TODO remove token
    get(token) {
        const options = token ? { params: { token }, anonymous: true } : undefined;
        return this.client.get(this.url(), options);
    }
    /** Update password (authenticated context) */
    updatePassword(passwordDetails) {
        /** Patch method is being used exclusively for updating password details */
        return this.client.patch(this.url(), passwordDetails);
    }
    // TODO remove token
    /** Update password (anonymous context) */
    resetPassword(passwordDetails, token) {
        const options = token ? { params: { token }, anonymous: true } : undefined;
        return this.client.patch(this.url(constants_1.APIPath.ResetPassword), passwordDetails, options);
    }
    /** Set user virtual MFA token secret */
    setVirtualMfaCode(secret) {
        return this.client.put(this.url(constants_1.APIPath.MeVirtualMfaCode), { secret });
    }
    /** Set user MFA Recovery Codes */
    setRecoveryCodes(recoveryCodes) {
        return this.client.put(this.url(constants_1.APIPath.MeRecoveryCodes), recoveryCodes);
    }
    /** Set user MFA Sms Enabled */
    setSmsMfaEnabled() {
        return this.client.patch(this.url(constants_1.APIPath.MeSmsMfaEnabled), undefined);
    }
    /** issue user MFA SMS Challenge */
    smsChallenges() {
        return this.client.put(this.url(constants_1.APIPath.MeSmsChallenges), undefined, { limitedAccessToken: true });
    }
    /** issue user Setup Device MFA Challenge */
    setupDeviceMfa(origin) {
        return this.client.put(this.url(constants_1.APIPath.MeSetupDeviceMfa), { origin }, { limitedAccessToken: true }).then((response) => {
            // Convert challenge and user.id from base64url to ArrayBuffer to confirm to WebAuthn spec
            response.publicKey.challenge = (0, functions_1.base64UrlToArrayBuffer)(response.publicKey.challenge);
            response.publicKey.user.id = (0, functions_1.base64UrlToArrayBuffer)(response.publicKey.user.id);
            return response;
        });
    }
    /** issue user Device MFA Challenge */
    deviceMfaChallenge(origin) {
        return this.client.put(this.url(constants_1.APIPath.MeDeviceMfaChallenge), { origin }, { limitedAccessToken: true }).then((response) => {
            // Convert challenge and user.id from base64url to ArrayBuffer to confirm to WebAuthn spec
            response.publicKey.challenge = (0, functions_1.base64UrlToArrayBuffer)(response.publicKey.challenge);
            // Decode each allowCredential ID
            if (response.publicKey.allowCredentials && response.publicKey.allowCredentials.length > 0) {
                response.publicKey.allowCredentials = response.publicKey.allowCredentials.map((cred) => ({
                    ...cred,
                    id: (0, functions_1.base64UrlToArrayBuffer)(cred.id),
                }));
            }
            return response;
        });
    }
    /** issue user Device MFA Challenge */
    completeDeviceMfaSetup(attestationKey, deviceName) {
        return this.client.put(this.url(constants_1.APIPath.MeCompleteDeviceMfaSetup), { attestationKey, deviceName }, { limitedAccessToken: true });
    }
    // TODO remove token
    getPermissions(token) {
        const options = token ? { params: { token }, anonymous: true } : undefined;
        return this.client.get(this.url(constants_1.APIPath.MePermissions), options);
    }
    update(user, token) {
        const options = token ? { params: { token }, anonymous: true } : undefined;
        return this.client.put(this.url(), user, options);
    }
    url(...extra) {
        return this.client.buildUrl(constants_1.APIPath.Me, ...extra);
    }
}
exports.MeClient = MeClient;
